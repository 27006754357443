export const termsOfUseMdEn = `
# General Terms and Conditions

This page is also available in [German](/general-terms-of-use-de). *** Diese Seite ist auch auf [Deutsch](/general-terms-of-use-de) verfügbar.

Current version from January 17, 2022. The terms used in these General Terms and Conditions are not gender specific.

## 1. General | Scope

- The company Ingo Ehrle & José Rodolfo Freitas GbR, Alpenblickstrasse 14, 88069 Tettnang, Germany, hereinafter referred to as “Play A Language!”, provides a learning platform for foreign languages via the website https://www.playalanguage.com. After registering, the user can take part in an interactive language adventure, which plays virtually in the target country, as well as complete exercises in the foreign language to improve language skills.
- The General Terms and Conditions (“GTC”) in the version valid at the time of registration or conclusion of the contract constitute the basis for the use of this platform and for all contracts between Play A Language! and the user of this platform. In this respect, there is no separate contract text for the agreement on the use of the platform between the user and Play A Language! Rather, the content of the agreed contract results from these GTC, as well as further specific agreements during the registration or the order of chargeable services.
- These GTC shall also apply to all future transactions and contracts between Play A Language! and the user, without the need for any further reference by Play A Language! to this effect. The user acknowledges these GTC by using the services of the platform provided by Play A Language!
- Play A Language! does not accept any deviating, contradictory or supplementary terms and conditions unless they have been expressly agreed upon in writing between the contractual parties beforehand.

 
## 2. Free and chargeable services | Rights of Play A Language!

- Play A Language! grants the user access to the platform specified in paragraph 1 after registration. With regard to the exact content, functions and usage options which Play A Language! makes available to the user, a distinction is made between free and chargeable services. Free services are limited in their scope of functions. In the case of chargeable services, Play A Language! provides the user with certain agreed functions in return for payment of a fee. The use is limited in time to the term of the respective service.
- Play A Language! may discontinue, restrict and modify free services at any time, without prior notice, or convert previously free services into chargeable services. Play A Language! does not assume any obligation regarding maintenance, support or availability for free services, subject to the limitation of liability (paragraph 10). Furthermore, free services are independent of chargeable services. Therefore, the user has no right to access certain free services if the user has previously opted for chargeable services.
- Play A Language! is allowed to change chargeable services at any time without prior notice, for example by installing new versions or software updates. In doing so, technical specifications, functions or the design of chargeable services may change.
- Play A Language! may display advertising information to the user on the platform regardless of the type of services.
- Play A Language! may also engage subcontractors or external software manufacturers for the fulfillment of its contractual obligations and use their services.

 
## 3. Registration and user account

- In order to use the services of Play A Language!, a registration and the creation of a corresponding user account with personal data and the choice of a password is required. After registration, the user will receive an e-mail from Play A Language! to activate the account. By creating an account, the user enters into a contract with Play A Language! for the use of free services. There is no obligation for the user to conclude chargeable services.
- The data required for registration and the account must be provided by the user completely and correctly. The user must also ensure that this information is always up to date.
- The user is obliged to keep his previously chosen password secret and not to share it with other persons. Accordingly, the account created by the user may only be used by the user and never by third parties. The user must immediately inform Play A Language! of any known or suspected unauthorized use of his/her account by third parties and, in addition, immediately change the stored password. In this regard, the user shall be subject to the liability provisions set forth in paragraph 10.
- In order to use the platform provided by Play A Language!, the user requires additional technical services and devices, such as Internet access, as well as a computer or a smartphone. The user is responsible for technically maintaining these services and devices, as well as their software, and for adapting them to the respective technical requirements of the platform provided by Play A Language!
- Play A Language! may store and process the data provided by the user in accordance with the applicable provisions on data protection and the Privacy Policy of Play A Language!

 
## 4. Chargeable services and orders

- After registration, in addition to free services, chargeable services are also available to the user, which the user can use after submitting a corresponding order for the respective specified service period. Chargeable services are also offered in the form of subscriptions, which are automatically and continuously renewed on a recurring basis after the expiration of the respective subscription period. Subscriptions may be cancelled up to one (1) day prior to the expiration of the respective subscription term (see regulations in paragraph 9).
- Before placing an order, the user must ensure that the chargeable services meet his or her wishes and needs. Functions, features, scope and quality of the services are known to the user before placing the order and are ordered “as is”.
- By placing an order, the user makes a legally binding offer to conclude a contract for the use of chargeable services. The user submits his/her order to Play A Language! via an online order form, including information about the desired chargeable services, the user’s personal data, and the corresponding payment information. Before submitting an order, this data can be viewed again, checked and, if necessary, corrected or changed.
- After receipt of the order, Play A Language! confirms the acceptance of the offer with an order confirmation, which is sent to the user by e-mail. Only then does the contract between the user and Play A Language! come into effect. The text of the contract results from the order placed by the user, these General Terms and Conditions and the order confirmation, and is stored by Play A Language! in accordance with the applicable provisions on data protection and the Privacy Policy of Play A Language!
- The chargeable services shall commence on the day of the order confirmation and may be used by the user until the end of the service period.
- The user may not assign its rights or obligations associated with an order to any third party without the prior written consent of Play A Language!
- Occasionally, Play A Language! may offer the user chargeable services free of charge within a special promotion for a certain service period. In this case, these services that are actually subject to a charge shall be deemed to be free services until the end of the service period; the respective provisions of these GTC regarding free services shall apply accordingly. The services temporarily provided free of charge may end after the service period and then automatically change into chargeable services. Accordingly, when ordering these services, the user must already provide payment details and agree that the services provided temporarily free of charge may automatically be converted into chargeable services after the end of the service period. The user can prevent this by cancelling the services in the user account up to one day before the end of the service period (paragraph 9).
- Play A Language! reserves the right to change the prices for chargeable services at any time at its sole discretion.
- Play A Language! reserves the right to refuse orders at its sole discretion.
- Changes or additions to an order must be made in writing, e.g. by e-mail.

 
## 5. Donations

- After registration, the user is free to support Play A Language! and the further development of the offer by making a financial donation. This is not a donation in the tax sense, but merely a voluntary financial contribution without equivalent consideration. In this respect, Play A Language! does not issue tax-relevant donation receipts.
- Donations cannot be exchanged for chargeable services at any time, nor can they be refunded.
- Play A Language! undertakes to use donations exclusively for the further development of the company’s services.
- By filling out an online donation form and entering the individually specified donation amount, the user makes a legally binding offer to enter into a donation agreement with Play A Language! Before submitting, this data can be viewed again, checked and, if necessary, corrected or changed.
- After receipt of the donation offer, Play A Language! confirms its acceptance with an order confirmation, which is sent to the user by e-mail. Only then does the contract between the user and Play A Language! come into effect. The text of the contract is derived from the donation request submitted by the user, these General Terms and Conditions and the order confirmation, and is stored by Play A Language! in accordance with the applicable provisions on data protection and the Privacy Policy of Play A Language!
- Play A Language! reserves the right to reject donation requests at its sole discretion.
- Any changes or amendments to a donation agreement must be made in writing, e.g. by email.

 
## 6. Use of the platform and property rights

- Play A Language! grants the user a non-exclusive, non-transferable, non-assignable and non-sublicensable right to use the corresponding services of the platform for personal and non-commercial purposes after registration and in case of an order in accordance with the respective contractual agreement. Platform content may not be copied, exported, decompiled, reconstructed, disassembled, transferred, assigned, licensed, shared, leased, rented, modified, reproduced, duplicated or sold by the user, nor may it be publicly demonstrated or otherwise commercially exploited. Unauthorized use of the platform by the user constitutes a breach of contract and may be subject to civil and criminal penalties. Accordingly, the user shall be liable for any unauthorized use of the platform.
- Under no circumstances shall the user have the right to obtain the source code of the platform or individual services.
- Play A Language! reserves the right, at its sole discretion, to temporarily or permanently suspend a user’s Account in the event of fraud, abuse or unauthorized use of the platform by a user, taking into account the particular circumstances of the individual case.
- All proprietary rights shall at all times remain with Play A Language! and any contractors, including but not limited to copyrights, database rights, trade secrets, trademarks and all other intellectual property rights and technical solutions related to the platform provided by Play A Language! This includes, in particular, the intellectual property rights to the teaching methodology used by Play A Language! to learn foreign languages via an interactive language adventure that is virtually set in the target country. The rights granted to the user through an order do not constitute a transfer of ownership rights to the user at any time.
- The user grants Play A Language! a non-exclusive, non-transferable, sub-licensable right, unlimited in space and content, to use the user data in connection with the provision of the services. All rights to the user data shall remain with the user. Play A Language! is not liable for user data.

 
## 7. Availability, support and maintenance for chargeable services

- Play A Language! will use reasonable commercial efforts to provide those users who have elected and paid for chargeable services with ninety percent (90%) availability of those services over the Internet for the calendar year. Excluded from this is the temporary unavailability of the platform due to planned or unplanned maintenance work, or the unavailability of the platform for reasons beyond the control of Play A Language! This includes, in particular, user-inflicted reasons for the non-availability of the platform, such as defective or faulty Internet access or technical devices that do not meet the technical requirements of the platform provided by Play A Language!
- Play A Language! provides support and maintenance for chargeable services to those users who have opted for and paid for such services. Support requests may be sent by e-mail to hello@playalanguage.com or to any other e-mail address provided by Play A Language! for support requests. Play A Language! undertakes to correct faulty and reproducible functions and so-called bugs within a commercially reasonable period of time and depending on the prioritization determined at its own discretion, and to provide the user with appropriate software updates.

 
## 8. Compensation for services and terms of payment, restrictions on set-off and retention

- The user shall pay the entire remuneration agreed upon in the order for services subject to a charge. For this purpose, various payment methods are available on the platform provided by Play A Language! such as payment by credit card or PayPal. Play A Language! does not accept payment methods other than those offered on the platform.
- If the chargeable services are a subscription, the user’s payment obligation to Play A Language! is automatically renewed on the day the subscription is renewed.  The user may prevent this by cancelling the services in his/her account up to one (1) day prior to the expiration of the service period (paragraph 9).
- In the case of subscriptions, Play A Language! may change the remuneration up to fifteen (15) days before the next renewal of the subscription. In this case, Play A Language! shall inform the user about the new prices by e-mail or via the provided platform. The changed remuneration shall be applied accordingly in the respective upcoming renewal of the subscription.
- The user shall only have the right to offset claims against Play A Language! if its counterclaims have been legally established or are undisputed. Likewise, the user may exercise its right to set off claims if notices of defects or counterclaims arising from the same contract can be asserted.
- The user may only exercise his right of retention if the respective counterclaim arises from the same contract.

 
## 9. Terms and terminations

- Upon creation of an account, the contract for the use of free services between Play A Language! and the user shall commence in accordance with paragraph 3. This contract shall run for an indefinite period of time and may be terminated by either the user or Play A Language! at any time with immediate effect.
- The term of an order for chargeable services shall begin and end on the dates specified in the order.
- If the chargeable services are a subscription, the subscription shall be renewed automatically, continuously and in accordance with the duration of the services used to date, if neither party terminates it in good time. Timely termination on the part of the user shall be made no later than one (1) day prior to the expiration of the service period via the corresponding menu item within the user’s account on the platform provided by Play A Language! Timely termination on the part of Play A Language! shall be made no later than one (1) day prior to the expiration of the service period by a simple written notice (e.g. e-mail) to the user. Cancellation shall become effective upon expiration of the last day of the current service period. Thereafter, the user shall no longer have access to the chargeable services used by the previous subscription.
- Chargeable services cannot be terminated before the end of the agreed service period.
- The right of the parties to extraordinary termination for good cause shall remain unaffected by the preceding provisions. In particular, such cause exists if a party materially breaches the contract and does not cure such breach within thirty (30) days after receipt of a simple written notice (e.g. e-mail), or if the user is in arrears with payments for more than thirty (30) days.

 
## 10. Liability

- Play A Language! excludes any claims of the user for damages. Excluded from this is liability for injury to life, body, health or breach of essential contractual obligations by Play A Language!, its legal representatives or vicarious agents, claims of the user for damages due to damage caused intentionally or by gross negligence by Play A Language!, its legal representatives or vicarious agents, liability for damages due to the absence of a guaranteed quality and for claims under the Product Liability Act (German: “Produkthaftungsgesetz”).
- In the event of a breach of material contractual obligations, Play A Language!, its legal representatives or vicarious agents shall only be liable for the foreseeable damage typical for the contract if such damage was caused by simple negligence.
- Play A Language! shall not be liable for damages arising from the breach of those obligations which do not constitute cardinal obligations and which were caused by slight negligence on the part of Play A Language!, its legal representative or vicarious agents.
- Furthermore, Play A Language! shall not be liable for any damage or loss suffered by the user caused directly or indirectly by the unauthorized use of the platform.
- In the event of data loss, Play A Language! shall only be liable up to the amount that covers the typical costs of restoring the data that would have been incurred if the user had made a proper and regular backup of the data.
- Subject to the foregoing, Play A Language!’s liability for damages arising out of any order, whether in contract, tort or otherwise, shall be limited to one hundred fifty percent (150%) of the amount of the payment for the affected order.
- Play A Language! cannot guarantee that the user will achieve any particular learning success in the foreign language learned by using the platform. All liability claims of the user in this regard are excluded. Any liability claims for Play A Language! other than those described in the preceding provisions are also excluded.
- The user shall be liable for any loss or damage incurred by Play A Language! as a result of the user intentionally or negligently disclosing information about his/her account or password to third parties, or as a result of information about his/her account or password becoming known to an unauthorized party. Excluded from this are those cases in which the user immediately informs Play A Language! of the suspicion of such a case (paragraph 3.c).

 
## 11.Revocation instruction and consent to the immediate execution of the contract

- If the user is a consumer (natural person who concludes the legal transaction for purposes which can predominantly be attributed neither to his commercial nor to his independent professional activity), he/she shall be entitled to a statutory right of revocation upon conclusion of a distance selling contract.
- The user agrees that Play A Language! may begin to execute the contract before the expiration of the revocation period. By this consent, the user loses his right to revoke the contract, provided that Play A Language! has started the execution of the contract. If the user then nevertheless revokes the contract, Play A Language! shall have the right to charge the user for the pro rata remuneration for the services provided up to the effective date of the revocation.
- – BEGINNING OF REVOCATION INSTRUCTIONS –
- Right of revocation
- You have the right to revoke this contract within fourteen (14) days without giving any reason. The revocation period is fourteen (14) days from the day of the conclusion of the contract.In order to exercise your right of revocation, you must inform us, Play A Language!, Ingo Ehrle & José Rodolfo Freitas GbR, Alpenblickstrasse 14, 88069 Tettnang, Germany, e-mail: hello@playalanguage.com, by means of a clear declaration (e.g. a letter or e-mail sent by post) of your decision to revoke this contract. You can use the model revocation form listed below, which is not mandatory. To comply with the revocation period, it is sufficient that you send the notification of the exercise of the right of revocation before the expiry of the revocation period.
- Consequences of the revocation
- If you revoke this contract, we will reimburse you all payments we have received from you, including the delivery costs (with the exception of additional costs resulting from the fact that you have chosen a type of delivery other than the cheapest standard delivery offered by us), without undue delay and at the latest within fourteen (14) days from the day on which we received the notification of your revocation of this contract. For this repayment, we will use the same means of payment that you used for the original transaction, unless expressly agreed otherwise with you. In no case will you be charged for this repayment. If you have requested that the services begin during the revocation period, you must pay us a reasonable amount corresponding to the proportion of the services already provided up to the time you notify us of the exercise of the right of revocation with regard to this contract, compared to the total scope of the services provided for in the contract.
- – END OF REVOCATION INSTRUCTIONS –
- Model revocation form
- If you wish to revoke the contract, please fill out this form and send it back to: Play A Language!, Ingo Ehrle & José Rodolfo Freitas GbR, Alpenblickstrasse 14, 88069 Tettnang, Germany, hello@playalanguage.com
- I/we (*) hereby revoke the contract concluded by me/us (*) for the provision of the following services:
- Ordered on (*)/received on (*):
- Name of the consumer(s) (*):
- Address of the consumer(s) (*):
- Signature of consumer(s) (*): – required only in case of paper communication
- Date:
- (*) Please delete where inapplicable!

 
## 12.Changes and updates of the General Terms and Conditions

- Play A Language! may amend and/or supplement these GTC with effect for the future if this is necessary for technical, economic or legal reasons. In the event that Play A Language! does not obtain express consent from the user for a change to the GTC, Play A Language! shall inform the user of the changes and/or additions to the GTC in a timely manner, at least four (4) weeks before the changed GTC come into effect, by e-mail to the e-mail address provided by the user during registration.
- The user may object to the changes or amendments until the day of their intended entry into force. If the user does not exercise his right of objection by the day the changes or amendments come into force, the changes or amendments shall be deemed accepted by the user.
- If the user objects to the changes or additions to the new GTC, the contractual relationship shall continue with the previous GTC. In this case, Play A Language! may terminate the contract for the use of free or chargeable services with effect from the next possible ordinary termination date.

 
## 13.Final provisions and applicable law

- Should any provision relating to an order or to these GTC be or become invalid or void in whole or in part, the remainder of the contract between Play A Language! and the user shall remain in effect. The invalid or void provision, if any, shall be replaced by the statutory provisions.
- The law of the Federal Republic of Germany shall apply. The United Nations Convention on Contracts for the International Sale of Goods (CISG) is excluded. The statutory provisions on the limitation of the choice of law and on the applicability of mandatory provisions, in particular of the state in which the user as a consumer has his/her habitual residence, shall remain unaffected.

Play A Language!  
Ingo Ehrle & José Rodolfo Freitas GbR  
Current version: January 17, 2022  
`;

export const termsOfUseMdDe = `

# Allgemeine Geschäftsbedingungen

This page is also available in [English](/general-terms-of-use). *** Diese Seite ist auch auf [English](/general-terms-of-use) verfügbar.

Aktueller Stand vom 17. Januar 2022. Die in diesen Allgemeinen Geschäftsbedingungen verwendeten Begriffe sind nicht geschlechtsspezifisch.
## 1. Allgemeines und Geltungsbereich

- Das Unternehmen Ingo Ehrle & José Rodolfo Freitas GbR, Alpenblickstraße 14, 88069 Tettnang, Deutschland, im Folgenden „Play A Language!“, stellt über die Website https://www.playalanguage.com eine Plattform zum Erlernen von Fremdsprachen bereit. Nach der Registrierung kann der Anwender an einem interaktiven Sprachabenteuer teilnehmen, welches virtuell im Zielland spielt, sowie Übungen in der Fremdsprache absolvieren und auf diese Weise seine Sprachkenntnisse verbessern.
- Diese Allgemeinen Geschäftsbedingungen („AGB“) in ihrer zum Zeitpunkt der Registrierung bzw. des Vertragsabschlusses gültigen Fassung stellen die Grundlage für die Nutzung der Plattform und für alle Verträge zwischen Play A Language! und dem Anwender der Plattform dar. Für den Vertrag über die Nutzung der Plattform zwischen Anwender und Play A Language! besteht insofern kein gesonderter Vertragstext. Vielmehr ergibt sich der Inhalt des vereinbarten Vertrages aus diesen AGB, sowie weiteren spezifischen Vereinbarungen während der Registrierung oder der Bestellung von kostenpflichtigen Leistungen.
- Diese AGB gelten ebenso für alle zukünftigen Geschäfte und Verträge zwischen Play A Language! und dem Anwender, ohne dass es hierzu eines weiteren Hinweises durch Play A Language! bedarf. Der Anwender bestätigt diese AGB mit der Inanspruchnahme der Leistungen der von Play A Language! bereitgestellten Plattform.
- Abweichende, widersprechende oder ergänzende Geschäftsbedingungen erkennt Play A Language! nicht an, außer sie wurden zuvor ausdrücklich und in Schriftform zwischen den Vertragsparteien vereinbart.

 
## 2. Kostenfreie und kostenpflichtige Leistungen, Rechte von Play A Language!

- Play A Language! gewährt dem Anwender nach der Registrierung Zugang zur in Absatz 1 angegebenen Plattform. Bei den genauen Inhalten, Funktionen und Nutzungsmöglichkeiten, welche Play A Language! dem Anwender zur Verfügung stellt, wird zwischen kostenfreien und kostenpflichtigen Leistungen unterschieden. Kostenfreie Leistungen sind in ihrem Funktionsumfang eingeschränkt. Bei kostenpflichtigen Leistungen stellt Play A Language! dem Anwender gegen Zahlung einer Vergütung bestimmte vereinbarte Funktionen bereit. Die Nutzung ist zeitlich auf die Laufzeit der jeweiligen Leistung beschränkt.
- Play A Language! darf kostenfreie Leistungen zu jedem Zeitpunkt, ohne vorherige Frist und ohne eine gesonderte Vorankündigung einstellen, einschränken und abändern, bzw. bisher kostenfreie in kostenpflichtige Leistungen umwandeln. Play A Language! übernimmt für kostenfreie Leistungen vorbehaltlich der Haftungsbeschränkung (Absatz 10) keinerlei Verpflichtung bezüglich Wartung, Support oder Verfügbarkeit. Darüber hinaus sind kostenfreie Leistungen unabhängig von kostenpflichtigen Leistungen. Es besteht also seitens des Anwenders kein Recht auf den Zugang zu bestimmten kostenfreien Leistungen, falls sich dieser zuvor für kostenpflichtige Leistungen entschieden hat.
- Play A Language! darf kostenpflichtige Leistungen zu jedem Zeitpunkt, ohne vorherige Frist und ohne eine gesonderte Vorankündigung abändern, etwa durch das Einspielen neuer Versionen oder Software-Updates. Dabei können sich technische Spezifikationen, Funktionen oder die Gestaltung kostenpflichtiger Leistungen ändern.
- Play A Language! darf dem Anwender unabhängig von der Art der Leistungen Werbeinformationen auf der Plattform anzeigen.
- Play A Language! darf für die Erfüllung der Vertragspflichten auch Subunternehmer oder externer Software-Hersteller beauftragen und deren Dienste in Anspruch nehmen.

 
## 3. Registrierung und Anwender-Account

- Für die Nutzung der Leistungen von Play A Language! ist eine Registrierung und die Erstellung eines entsprechenden Anwender-Accounts mit persönlichen Angaben sowie der Wahl eines Passworts erforderlich. Nach der Registrierung erhält der Anwender von Play A Language! eine E-Mail zur Aktivierung des Accounts. Mit dem Anlegen eines Accounts schließt der Anwender einen Vertrag mit Play A Language! zur Nutzung kostenfreier Leistungen. Für den Anwender besteht keine Verpflichtung zum Abschluss kostenpflichtiger Leistungen.
- Die für Registrierung und Account erforderlichen Daten sind vom Anwender vollständig und richtig anzugeben. Ebenso hat der Anwender sicherzustellen, dass sich diese Angaben stets auf aktuellem Stand befinden.
- Der Anwender ist dazu verpflichtet, sein zuvor gewähltes Passwort geheim zu halten und es nicht mit anderen Personen zu teilen. Entsprechend darf der vom Anwender angelegte Account nur von ihm und niemals von Dritten verwendet werden. Der Anwender muss Play A Language! sofort über eine bekannte oder vermutete unbefugte Nutzung seines Accounts durch Dritte informieren und darüber hinaus unverzüglich das hinterlegte Passwort ändern. Für den Anwender gelten diesbezüglich die Haftungsregelungen aus Absatz 10.
- Für die Nutzung der von Play A Language! bereitgestellten Plattform benötigt der Anwender weitere technische Leistungen und Geräte, beispielsweise einen Internetzugang, sowie einen Computer bzw. ein Smartphone. Der Anwender ist selbst dafür verantwortlich, diese Leistungen und Geräte, sowie deren Software technisch zu warten und an die jeweiligen technischen Anforderungen der von Play A Language! bereitgestellten Plattform anzupassen.
- Play A Language! darf die vom Anwender angegebenen Daten speichern und verarbeiten, gemäß der jeweils gültigen Bestimmungen zum Datenschutz und der Datenschutzerklärung von Play A Language!

 
## 4. Kostenpflichtige Leistungen und Bestellungen

- Nach der Registrierung stehen dem Anwender neben kostenfreien auch kostenpflichtige Leistungen zur Verfügung, welche der Anwender nach Abgabe einer entsprechenden Bestellung für die jeweils angegebene Leistungsdauer nutzen kann. Kostenpflichtige Leistungen werden auch in Form von Abonnements angeboten, die sich nach Ablauf der jeweiligen Abonnementlaufzeit automatisch und fortlaufend, also auf wiederkehrender Basis verlängern. Abonnements können bis zu einem (1) Tag vor Ablauf der jeweiligen Abonnementlaufzeit gekündigt werden (siehe Regelungen in Absatz 9).
- Der Anwender hat vor Abgabe einer Bestellung sicherzustellen, dass die kostenpflichtigen Leistungen seinen Wünschen und Bedürfnissen entsprechen. Funktionen, Merkmale, Umfang und Qualität der Leistungen sind dem Anwender vor der Bestellung bekannt und werden „wie gesehen“ bestellt.
- Mit einer Bestellung gibt der Anwender ein rechtverbindliches Angebot auf Abschluss eines Vertrags über die Nutzung kostenpflichtiger Leistungen ab. Der Anwender übermittelt seine Bestellung über ein Online-Bestellformular an Play A Language!, mit Angaben zu den gewünschten kostenpflichtigen Leistungen, den persönlichen Daten des Anwenders sowie zu den entsprechenden Zahlungsinformationen. Vor dem Absenden einer Bestellung können diese Daten erneut eingesehen, überprüft und falls notwendig korrigiert bzw. geändert werden.
- Play A Language! bestätigt nach Erhalt der Bestellung die Annahme des Angebots mit einer Auftragsbestätigung, welche dem Anwender per E-Mail übermittelt wird. Erst dadurch kommt der Vertrag zwischen Anwender und Play A Language! zustande. Der Vertragstext ergibt sich aus der vom Anwender aufgegebenen Bestellung, diesen AGB und der Auftragsbestätigung und wird von Play A Language! gemäß den jeweils gültigen Bestimmungen zum Datenschutz und der Datenschutzerklärung von Play A Language! gespeichert.
- Die kostenpflichtigen Leistungen beginnen ab dem Tag der Auftragsbestätigung und können vom Anwender bis zum Ende der Leistungsdauer in Anspruch genommen werden.
- Der Anwender darf seine mit einer Bestellung verbundenen Rechte oder Verpflichtungen nicht ohne das vorherige schriftliche Einverständnis von Play A Language! an Dritte abtreten.
- Gelegentlich kann Play A Language! dem Anwender kostenpflichtige Leistungen innerhalb einer Sonderaktion für eine bestimmte Leistungsdauer kostenlos anbieten. In diesem Fall gelten diese eigentlich kostenpflichtigen Leistungen bis zum Ende der Leistungsdauer als kostenfreie Leistungen, entsprechend gelten die jeweiligen Regelungen dieser AGB zu kostenfreien Leistungen. Die vorübergehend kostenlos bereitgestellten Leistungen können nach der Leistungsdauer enden und anschließend automatisch in kostenpflichtige Leistungen übergehen. Entsprechend muss der Anwender bereits bei der Bestellung dieser Leistungen seine Zahlungsdaten angeben und zustimmen, dass die vorübergehend kostenlos bereitgestellten Leistungen nach Ende der Leistungsdauer automatisch in kostenpflichtige Leistungen umgewandelt werden können. Der Anwender kann dies verhindern, indem er die Leistungen bis zu einem Tag vor Ablauf der Leistungsdauer in seinem Account kündigt (Absatz 9).
- Play A Language! behält sich das Recht vor, die Preise für kostenpflichtige Leistungen nach eigenem Ermessen jederzeit zu ändern.
- Play A Language! behält sich nach eigenem Ermessen das Recht vor, Bestellungen abzulehnen.
- Änderungen oder Ergänzungen zu einer Bestellung müssen schriftlich erfolgen, z.B. per E-Mail.

 
## 5. Spenden

- Nach der Registrierung steht es dem Anwender offen, Play A Language! und die weitere Entwicklung des Angebots durch eine finanzielle Spende zu unterstützen. Dabei handelt es sich nicht um Spenden im steuerlichen Sinne, sondern lediglich um einen freiwilligen finanziellen Beitrag ohne äquivalente Gegenleistung. Play A Language! stellt insofern keine steuerrelevanten Spendenbescheinigungen aus.
- Spenden können zu keinem Zeitpunkt für kostenpflichtige Leistungen eingetauscht werden und auch nicht wieder erstattet werden.
- Play A Language! verpflichtet sich dazu, Spenden ausschließlich für die weitere Entwicklung des unternehmerischen Angebots einzusetzen.
- Mit dem Ausfüllen eines Online-Spendenformulars und der Eingabe des individuell festgelegten Spendenbetrags gibt der Anwender ein rechtverbindliches Angebot auf Abschluss eines Spendenvertrags mit Play A Language! ab. Vor dem Absenden können diese Daten erneut eingesehen, überprüft und falls notwendig korrigiert bzw. geändert werden.
- Play A Language! bestätigt nach Erhalt des Spendenangebots dessen Annahme mit einer Auftragsbestätigung, welche dem Anwender per E-Mail übermittelt wird. Erst dadurch kommt der Vertrag zwischen Anwender und Play A Language! zustande. Der Vertragstext ergibt sich aus dem vom Anwender übermittelten Spendenantrag, diesen AGB und der Auftragsbestätigung und wird von Play A Language! gemäß den jeweils gültigen Bestimmungen zum Datenschutz und der Datenschutzerklärung von Play A Language! gespeichert.
- Play A Language! behält sich nach eigenem Ermessen das Recht vor, Spendenanträge abzulehnen.
- Änderungen oder Ergänzungen zu einem Spendenvertrag müssen schriftlich erfolgen, z.B. per E-Mail.

 
## 6. Nutzung der Plattform und Eigentumsrechte

- Play A Language! gewährt dem Anwender nach Registrierung und im Falle einer Bestellung nach Maßgabe der jeweiligen Vertragsvereinbarung ein einfaches, nicht-exklusives, nicht übertragbares, nicht abtretbares und nicht unterlizenzierbares Recht, die entsprechenden Leistungen der Plattform für persönliche und nicht kommerzielle Zwecke zu nutzen. Plattform-Inhalte dürfen vom Anwender nicht kopiert, exportiert, dekompiliert, rekonstruiert, zerlegt, übertragen, abgetreten, lizenziert, geteilt, verpachtet, vermietet, verändert, reproduziert, vervielfältigt oder verkauft werden, ebenso sind öffentliche Vorführungen oder andere kommerzielle Verwertungen verboten. Das unbefugte Nutzen der Plattform durch den Anwender stellt eine Vertragsverletzung dar und kann zivil- und strafrechtlich geahndet werden. Entsprechend haftet der Anwender für jegliches unbefugte Nutzen der Plattform.
- Der Anwender hat unter keinen Umständen das Recht, den Quellcode der Plattform oder einzelner Leistungen zu erhalten.
- Play A Language! behält sich nach eigenem Ermessen das Recht vor, unter Berücksichtigung der besonderen Umstände des Einzelfalles und im Falle eines Betrugs, Missbrauchs oder unbefugten Nutzens der Plattform durch einen Anwender, dessen Account vorübergehend oder dauerhaft zu sperren.
- Sämtliche Eigentumsrechte verbleiben zu jedem Zeitpunkt bei Play A Language! und etwaigen Auftragnehmern, insbesondere Urheberrechte, Datenbankrechte, Geschäftsgeheimnisse, Marken und alle übrigen Rechte an geistigem Eigentum und technischen Lösungen, welche in Zusammenhang mit der von Play A Language! bereitgestellten Plattform stehen. Dazu zählen insbesondere auch die geistigen Eigentumsrechte an der von Play A Language! verwendeten Lehrmethodik, Fremdsprachen über ein interaktives Sprachabenteuer zu lernen, welches virtuell im Zielland spielt. Die dem Anwender durch eine Bestellung gewährten Rechte stellen zu keinem Zeitpunkt eine Übertragung von Eigentumsrechten an den Anwender dar.
- Der Anwender gewährt Play A Language! ein einfaches, nicht übertragbares, unterlizenzierbares, räumlich und inhaltlich unbeschränktes Recht, die Anwenderdaten in Verbindung mit der Erbringung der Leistungen zu nutzen. Sämtliche Rechte an den Anwenderdaten verbleiben beim Anwender. Play A Language! haftet nicht für Anwenderdaten.

 
## 7. Verfügbarkeit, Support und Wartung für kostenpflichtige Leistungen

- Play A Language! unternimmt angemessene wirtschaftliche Anstrengungen, um jenen Anwendern, welche sich für kostenpflichtige Leistungen entschieden und diese bezahlt haben, eine Verfügbarkeit dieser Leistungen über das Internet von neunzig Prozent (90%) des Kalenderjahres zu gewährleisten. Ausgenommen davon ist die vorübergehende Nichtverfügbarkeit der Plattform aufgrund geplanter oder ungeplanter Wartungsarbeiten, bzw. die Nichtverfügbarkeit der Plattform aus Gründen, die außerhalb des Einflussbereichs von Play A Language! liegen. Dazu zählen insbesondere nutzerseitig verschuldete Gründe für die Nichterreichbarkeit der Plattform, wie etwa ein mangelhafter oder fehlerhafter Internetzugang bzw. technische Geräte, welche nicht den technischen Anforderungen der von Play A Language! bereitgestellten Plattform genügen.
- Play A Language! bietet jenen Anwendern, welche sich für kostenpflichtige Leistungen entschieden und diese bezahlt haben, Support und Wartung für diese Leistungen. Supportanfragen können per E-Mail an hello@playalanguage.com oder an eine andere von Play A Language! für Supportanfragen zur Verfügung gestellte E-Mail-Adresse gerichtet werden. Play A Language! verpflichtet sich, fehlerhafte und reproduzierbare Funktionen und sogenannte Bugs innerhalb einer wirtschaftlich angemessenen Zeitdauer und abhängig von der nach eigenem Ermessen festgelegten Priorisierung zu beheben und dem Anwender entsprechende Software-Aktualisierungen bereitzustellen.

 
## 8. Leistungsvergütung und Zahlungsbedingungen, Beschränkungen zu Aufrechnung und Zurückbehaltung

- Der Anwender hat die gesamte, in der Bestellung kostenpflichtiger Leistungen vereinbarte Vergütung zu begleichen. Hierfür stehen auf der von Play A Language! bereitgestellten Plattform verschiedene Zahlungsarten zur Verfügung, etwa das Bezahlen mit Kreditkarten oder mit PayPal. Andere als die auf der Plattform angebotenen Zahlungsmethoden werden von Play A Language! nicht akzeptiert.
- Handelt es sich bei den kostenpflichtigen Leistungen um ein Abonnement, so erneuert sich die Zahlungsverpflichtung des Anwenders gegenüber Play A Language! automatisch an dem Tag, an dem sich das Abonnement erneuert.  Der Anwender kann dies verhindern, indem er die Leistungen bis zu einem (1) Tag vor Ablauf der Leistungsdauer in seinem Account kündigt (Absatz 9).
- Bei Abonnements darf Play A Language! die Vergütung bis zu fünfzehn (15) Tage vor der nächsten Erneuerung des Abonnements ändern. In diesem Fall informiert Play A Language! den Anwender über die neuen Preise per E-Mail oder über die bereitgestellte Plattform. Die veränderte Vergütung findet entsprechende Anwendung in der jeweils anstehenden Erneuerung des Abonnements.
- Der Anwender hat nur dann ein Recht zur Aufrechnung von Forderungen gegenüber Play A Language!, falls seine Gegenansprüche rechtskräftig festgestellt oder unbestritten sind. Ebenso kann der Anwender von seinem Recht zur Aufrechnung von Forderungen Gebrauch machen, falls Mängelrügen oder Gegenansprüche aus demselben Vertrag geltend gemacht werden können.
- Der Anwender darf nur dann von seinem Zurückbehaltungsrecht Gebrauch machen, wenn der jeweilige Gegenanspruch aus demselben Vertrag herrührt.

 
## 9. Laufzeiten und Kündigungen

- Mit dem Anlegen eines Accounts beginnt gemäß Absatz 3 der Vertrag zur Nutzung kostenfreier Leistungen zwischen Play A Language! und dem Anwender. Dieser Vertrag läuft auf unbestimmte Zeit und kann sowohl vom Anwender als auch von Play A Language! jederzeit mit sofortiger Wirkung gekündigt werden.
- Die Laufzeit einer Bestellung kostenpflichtiger Leistungen beginnt und endet zu den jeweils in der Bestellung angegebenen Zeitpunkten.
- Handelt es sich bei den kostenpflichtigen Leistungen um ein Abonnement, so verlängert sich das Abonnement automatisch, fortlaufend und entsprechend der bisher in Anspruch genommenen Leistungsdauer, falls keine der Parteien dieses rechtzeitig kündigt. Eine rechtzeitige Kündigung seitens des Anwenders erfolgt bis spätestens einem (1) Tag vor Ablauf der Leistungsdauer über den entsprechende Menüpunkt innerhalb des Anwender-Accounts auf der von Play A Language! bereitgestellten Plattform. Eine rechtzeitige Kündigung seitens Play A Language! erfolgt bis spätestens einem (1) Tag vor Ablauf der Leistungsdauer über eine einfache, schriftliche Anzeige (z.B. E-Mail) gegenüber dem Anwender. Eine Kündigung wird jeweils nach Ablauf des letzten Tages der laufenden Leistungsdauer wirksam. Anschließend stehen dem Anwender die durch das bisherige Abonnement in Anspruch genommenen kostenpflichtigen Leistungen nicht weiter zur Verfügung.
- Kostenpflichtige Leistungen können nicht vor Ablauf der vereinbarten Leistungsdauer ordentlich gekündigt werden.
- Das Recht der Parteien zur außerordentlichen Kündigung aus wichtigem Grund bleibt von den vorangegangenen Bestimmungen unberührt. Insbesondere besteht ein solcher Grund, falls eine Partei den Vertrag wesentlich verletzt und diese Verletzung nicht innerhalb dreißig (30) Tagen nach Erhalt einer einfachen, schriftlichen Anzeige (z.B. E-Mail) heilt, oder falls sich der Anwender für mehr als dreißig (30) Tage mit seinen Zahlungen im Rückstand befindet.

 
## 10. Haftung

- Play A Language! schließt Ansprüche des Anwenders auf Schadensersatz aus. Ausgenommen hiervon ist die Haftung für die Verletzung des Lebens, des Körpers, der Gesundheit oder der Verletzung wesentliche Vertragspflichten durch Play A Language!, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen, Ansprüche des Anwenders auf Schadensersatz durch vorsätzlich oder grob fahrlässig verursachte Schäden durch Play A Language!, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen, Haftung für Schäden aufgrund des Fehlens einer garantierten Beschaffenheit und für Forderungen aus dem Produkthaftungsgesetz.
- Bei der Verletzung wesentlicher Vertragspflichten haftet Play A Language!, seine gesetzlichen Vertreter oder Erfüllungsgehilfen nur für den vertragstypischen, vorhersehbaren Schaden, wenn dieser einfach fahrlässig verursacht wurde.
- Play A Language! haftet nicht für Schäden aus der Verletzung von jenen Pflichten, die keine Kardinalpflichten darstellen und die leicht fahrlässig durch Play A Language!, seinen gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden.
- Weiterhin haftet Play A Language! nicht für jene Schäden oder Verluste des Anwenders, welche mittel- oder unmittelbar durch die nicht autorisierte Verwendung der Plattform verursacht wurden.
- Im Falle von Datenverlusten haftet Play A Language! lediglich bis zu jenem Betrag, welcher die typischen Kosten für die Wiederherstellung der Daten abdeckt, welche entstanden wären, falls der Anwender eine ordnungsgemäße und regelmäßige Sicherung der Daten vorgenommen hätte.
- Vorbehaltlich der vorangegangenen Regelungen beschränkt sich die Haftung von Play A Language! für Schäden aus einer Bestellung, sei es aus Vertrag, Delikt oder aus anderen Gründen auf einhundertfünfzig Prozent (150 %) der Summe der Vergütung der betroffenen Bestellung.
- Play A Language! kann nicht garantieren, dass der Anwender durch das Verwenden der Plattform einen bestimmten Lernerfolg in der erlernten Fremdsprache erzielt. Sämtliche Haftungsansprüche des Anwenders diesbezüglich sind ausgeschlossen. Andere als die in den vorangegangenen Regelungen beschriebenen Haftungsfälle für Play A Language! werden ebenso ausgeschlossen.
- Der Anwender haftet für Verluste oder Schäden, die Play A Language! dadurch entstehen, dass der Anwender vorsätzlich oder fahrlässig Dritten die Informationen zu seinem Account bzw. Passwort weitergegeben hat, oder dass Informationen zu seinem Account bzw. Passwort einer nicht autorisierten Partei bekannt geworden sind. Ausgenommen davon sind jene Fälle, in denen der Anwender Play A Language! unverzüglich über den Verdacht eines derartigen Falls informiert (Absatz 3.c).

 
## 11.Widerrufsbelehrung und Zustimmung zur sofortige Vertragsdurchführung

- Handelt es sich beim Anwender um einen Verbraucher (natürliche Person, welche das Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können), steht ihm bei Abschluss eines Fernabsatzvertrages ein gesetzliches Widerrufsrecht zu.
- Der Anwender erklärt sich damit einverstanden, dass Play A Language! bereits vor Ablauf der Widerrufsfrist mit der Durchführung des Vertrages beginnt. Durch diese Zustimmung verliert der Anwender sein Recht zum Widerruf des Vertrags, sofern Play A Language! mit der Durchführung des Vertrags begonnen hat. Widerruft der Anwender daraufhin dennoch einen Vertrag, so hat Play A Language! das Recht, dem Anwender die anteilige Vergütung der bis zum Wirksamwerden des Widerrufs erbrachten Leistungen zu berechnen.
- – BEGINN WIDERRUFSBELEHRUNG –
- Widerrufsrecht
- Du hast das Recht, binnen vierzehn (14) Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn (14) Tage ab dem Tag des Abschlusses des Vertrags. Um Dein Widerrufsrecht auszuüben, musst Du uns, Play A Language!, Ingo Ehrle & José Rodolfo Freitas GbR, Alpenblickstraße 14, 88069 Tettnang, Deutschland, E-Mail: hello@playalanguage.com, mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail) über Deinen Entschluss, diesen Vertrag zu widerrufen, informieren. Du kannst dafür das weiter unten aufgeführte Muster-Widerrufsformular verwenden, welches jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Du die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absendest.
- Folgen des Widerrufs
- Wenn Du diesen Vertrag widerrufst, haben wir Dir alle Zahlungen, die wir von Dir erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Du eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt hast), unverzüglich und spätestens binnen vierzehn (14) Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Deinen Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Du bei der ursprünglichen Transaktion eingesetzt hast, es sei denn, mit Dir wurde ausdrücklich etwas anderes vereinbart. In keinem Fall werden Dir wegen dieser Rückzahlung Entgelte berechnet. Hast Du verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so hast Du uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Du uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichtest, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
- – ENDE DER WIDERRUFSBELEHRUNG –
- Muster-Widerrufsformular
- Wenn Du den Vertrag widerrufen willst, dann fülle bitte dieses Formular aus und sende es zurück an: Play A Language!, Ingo Ehrle & José Rodolfo Freitas GbR, Alpenblickstraße 14, 88069 Tettnang, Deutschland, hello@playalanguage.com
- Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistungen (*):
- Bestellt am (*)/erhalten am (*):
- Name des/der Verbraucher/s (*):
- Anschrift des/der Verbraucher/s (*):
- Unterschrift des/der Verbraucher(*): – nur erforderlich bei Mitteilung in Papierform
- Datum:
- (*) Unzutreffendes bitte streichen!

 
## 12.Änderungen und Aktualisierungen der AGB

- Play A Language! kann diese AGB mit Wirkung für die Zukunft ändern und/oder ergänzen, sofern dies aus technischen, wirtschaftlichen oder rechtlichen Gründen erforderlich ist. Sollte Play A Language! zu einer Änderung der AGB keine ausdrückliche Einwilligung vom Anwender einholen, so informiert Play A Language! den Anwender über die Änderungen und/oder Ergänzungen der AGB rechtzeitig, mindestens vier (4) Wochen vor Inkrafttreten der geänderten AGB, per E-Mail, an die vom Anwender in der Registrierung angegebene E-Mail-Adresse.
- Der Anwender kann den Änderungen oder Ergänzungen bis zum Tag ihres beabsichtigten Inkrafttretens widersprechen. Sollte der Anwender von seinem Recht des Widerspruchs bis zum Tag des Inkrafttretens der Änderungen oder Ergänzungen keinen Gebrauch machen, so gelten die Änderungen oder Ergänzungen als vom Anwender angenommen.
- Widerspricht der Anwender den Änderungen oder Ergänzungen der neuen AGB, so wird die Vertragsbeziehung mit den bisherigen AGB fortgesetzt. In diesem Fall darf Play A Language! den Vertrag über die Nutzung kostenfreier oder kostenpflichtiger Leistungen mit Wirkung zum nächstmöglichen ordentlichen Kündigungsdatum kündigen.

 
## ## 13.Schlussbestimmungen und geltendes Recht

- Sollte eine Regelung bezüglich einer Bestellung oder bezüglich dieser AGB ganz oder teilweise unwirksam bzw. nichtig sein oder werden, so bleibt der Vertrag zwischen Play A Language! und dem Anwender im Übrigen wirksam. An die Stelle der unwirksamen oder nichtigen Regelung treten, soweit vorhanden, die gesetzlichen Vorschriften.
- Es gilt das Recht der Bundesrepublik Deutschland. Das Übereinkommen der Vereinten Nationen über Verträge über den internationalen Warenkauf (CISG) wird ausgeschlossen. Die gesetzlichen Vorschriften zur Beschränkung der Rechtswahl und zur Anwendbarkeit zwingender Vorschriften insbesondere des Staates, in dem der Anwender als Verbraucher seinen gewöhnlichen Aufenthalt hat, bleiben unberührt.

 
 

Play A Language!   
Ingo Ehrle & José Rodolfo Freitas GbR  
Aktueller Stand: 17. Januar 2022  

`;
