/**
 * Build element when the left tab is clicked
 * @param {any} props
 * @returns {JSX.Element}
 */
export function TabContentRight(props) {
    return (
        <div id="tab-1-content" className="w-11/12 mx-auto opacity-0 show">
            <div className="items-center justify-center gap-8 text-center tab-1-content-inner sm:text-left md:flex" >
                <div style={{maxWidth:"500px", margin:"auto", display:"flex", alignItems:"center", flexDirection:"column"}}>
                    <h1 className="mx-0 mt-0 mb-4 text-3xl font-bold text-white sm:text-2xl md:text-xl lg:text-2xl sm:inline-block">{props.header}</h1>
                    <p className="max-w-xl mx-0 mt-2 mb-4 text-xl leading-none sm:text-xl xl:text-xl">
                        {props.paragraph}
                    </p>
                    <p className="max-w-xl mx-0 mt-2 mb-4 text-xl leading-none sm:text-xl xl:text-xl">
                        {props.paragraph2}
                    </p>
                    <a
                        className="items-center text-center block px-5 py-3 rounded-xl text-sm text-white uppercase border-none cursor-pointer lg:text-base btn hover:opacity-90"
                        href={props.href}
                        rel={props.rel}
                        target={props.target}
                    >
                        {props.span}
                    </a>
                </div>
                    <img
                        alt={props.alt}
                        className="w-10/12 mx-auto mt-8 sm:w-4/12 md:w-5/12 lg:w-4/12"
                        src={props.src}
                    />
            </div>
        </div>
    );
}
