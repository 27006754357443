/**
 * Builds the element that will house the content selected
 * @param {any} props
 * @returns {JSX.Element}
 */
export function TabContentCurrent(props) {
  return (
    <section className="px-0 py-12 text-white bg-black">
      <div className="max-w-full grid-cols-3 px-8 py-0 m-auto overflow-hidden ">
        {props.tabContent}
      </div>
    </section>
  );
}
