
export const imprintMdEn = `
# Imprint

This page is also available in [German](/impressum). *** Diese Seite ist auch auf [Deutsch](/impressum) verfügbar.

## Information according to § 5 TMG

Play a Language!  
Ingo Ehrle & José Rodolfo Freitas GbR  
Alpenblickstraße 14  
88069 Tettnang  
Germany  

## Represented by:
Ingo Ehrle  
José Rodolfo Freitas  

## Contact

Phone: +49 (0) 178 180 6372  
E-Mail: info@playalanguage.com

## Editorially responsible

Ingo Ehrle  
Alpenblickstraße 14  
88069 Tettnang  
Germany  

## EU Dispute Resolution

The European Commission provides a platform for online dispute resolution: https://ec.europa.eu/consumers/odr/.  
You can find our e-mail address in the imprint above.

## Consumer dispute resolution/universal arbitration body

We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.
`;
export const imprintMdDe = `
# Impressum

This page is also available in [English](/imprint). *** Diese Seite ist auch auf [English](/imprint) verfügbar.

## Angaben gemäß § 5 TMG

Play a Language!  
Ingo Ehrle & José Rodolfo Freitas GbR  
Alpenblickstraße 14  
88069 Tettnang  
Deutschland  

## Vertreten durch:
Ingo Ehrle  
José Rodolfo Freitas  

##  Kontakt

Telefon: +49 (0) 178 180 6372  
E-Mail: info@playalanguage.com  

## Redaktionell verantwortlich

Ingo Ehrle  
Alpenblickstraße 14  
88069 Tettnang  
Deutschland  

## EU-Streitschlichtung

Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr/.
Unsere E-Mail-Adresse finden Sie oben im Impressum.

## Verbraucher­streit­beilegung/Universal­schlichtungs­stelle

Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
`;

