export const footerLeft = [
  {
    href: "/faq",
    text: "FAQ",
  },
  {
    href: "/imprint",
    text: "Imprint",
  },
  {
    href: "/general-terms-of-use",
    text: "General terms of use",
  },
  {
    href: "/privacy-policy",
    text: "Privacy Policy",
  },
];

export const footerLeftCenter = [
  {
    href: "https://www.twitter.com/playalanguage",
    text: "Twitter",
  },
  {
    href: "https://www.facebook.com/playalanguage",
    text: "Facebook",
  },
  {
    href: "https://www.instagram.com/playalanguage",
    text: "Instagram",
  },
];

export const footerRightCenter = [

];

export const footerRight = [

];

export const middleTabLeft = [
  {
    src: "https://i.ibb.co/DpdN7Gn/tab-content-2-1.png",
    pTop: "Watch on your TV",
    pBottom:
      "Smart phones, tablets, and desktop computers",
    alt: "tv",
  },
];

export const middleTabCenter = [
  {
    src: "https://i.ibb.co/R3r1SPX/tab-content-2-2.png",
    pTop: "Watch instantly or download for later",
    pBottom: "Available on phone and tablet, wherever you go.",
    alt: "mobile",
  },
];

export const middleTabRight = [
  {
    src: "https://i.ibb.co/gDhnwWn/tab-content-2-3.png",
    pTop: "Use any device",
    pBottom: "Play anywhere.",
    alt: "play anywhere",
  },
];

export const tableRowOne = [
  {
    span1: "Monthly price after beta phase",
    span2: "$8.99",
    span3: "$12.99",
    span4: "$15.99",
  },
];

export const tableRowTwo = [
  {
    span1: "HD Available",
    span2: "\u2613",
    span3: "\u2713",
    span4: "\u2713",
  },
];

export const tableRowThree = [
  {
    span1: "Ultra HD Available",
    span2: "\u2613",
    span3: "\u2613",
    span4: "\u2713",
  },
];

export const tableRowFour = [
  {
    span1: "Screens you can watch on at the same time",
    span2: "1",
    span3: "2",
    span4: "4",
  },
];

export const tableRowFive = [
  {
    span1: "Watch on your laptop, TV, phone and tablet",
    span2: "\u2713",
    span3: "\u2713",
    span4: "\u2713",
  },
];

export const tableRowSix = [
  {
    span1: "Unlimited movies and TV shows",
    span2: "\u2713",
    span3: "\u2713",
    span4: "\u2713",
  },
];

export const tableRowSeven = [
  {
    span1: "Cancel anytime",
    span2: "\u2713",
    span3: "\u2713",
    span4: "\u2713",
  },
];

export const tableRowEight = [
  {
    span1: "First month free",
    span2: "\u2713",
    span3: "\u2713",
    span4: "\u2713",
  },
];
