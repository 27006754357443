import { FaDoorOpen, FaTabletAlt, FaTags } from "react-icons/fa";
import icon1 from "../assets/icon1.png";
import icon2 from "../assets/icon2.png";
import icon3 from "../assets/icon3.png";
import logo from "../assets/pallogo.png";
import React from "react";

/**
 * Builds the element that is used to select tabs
 * @param {any} props
 * @returns {JSX.Element}
 */


export function TabSelector(props) {
  return (
    <section className="border-b-0 border-r-0 border-gray-600 bg-neutral-900">
      <div className="grid max-w-screen-sm grid-cols-3 px-1 pt-1 m-auto overflow-hidden text-center xl:max-w-screen-xl sm:pt-2">
        <div
          className={["tab-border ", props.borderBottom].join("")}
          id="tab-1"
          onClick={props.onClickSetTab}
        >
          <img
              className="inline pb-1 m-0 text-4xl sm:text-7xl grey-img hover:white-img icon-img"
              src={icon1}
          />
          <p className="text-xs sm:m-0 sm:block md:text-base">{props.pLeft}</p>
        </div>

        <div
          className={props.borderBottom}
          id="tab-2"
          onClick={props.onClickSetTab}
        >
          <img
              className="inline pb-1 m-0 text-4xl sm:text-7xl grey-img hover:white-img icon-img"
              src={icon2}
          />
          <p className="text-xs sm:m-0 sm:block md:text-base">
            {props.pMiddle}
          </p>
        </div>

        <div
          className={props.borderBottom}
          id="tab-3"
          onClick={props.onClickSetTab}
        >
          <img
              className="inline pb-1 m-0 text-4xl sm:text-7xl grey-img hover:white-img icon-img"
              src={icon3}
          />
          <p className="text-xs sm:m-0 sm:block md:text-base">{props.pRight}</p>
        </div>
      </div>
    </section>
  );
}
