import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import App from "./App";
import ReactMarkdown from "react-markdown";
import {faqMd} from './data/faq'
import {imprintMdEn, imprintMdDe} from './data/imprint'
import styled from "@emotion/styled";
import {privacyMdDe, privacyMdEn} from "./data/pricacy";
import {termsOfUseMdDe, termsOfUseMdEn} from "./data/termsOfUse";

const MdBase = styled(ReactMarkdown)`

  color: #505050;
  max-width: 800px;
  width: 95%;
  margin: 10px auto;
  border-radius: 10px;
  background: white;
  border: 1px solid #838383;
  overflow: hidden;

  h1 {
    font-size: 1.8em;
    font-weight: 600;
    padding: 10px;
    background: #ea8d0d;
    color: white;
  }
  
  a {
    color: #333333;
    text-decoration: underline;
  }
  
  a:hover {
    color: black;
  }

  h2 {
    font-size: 1.2em;
    font-weight: 500;
    margin: 10px;
    border-bottom: 1px solid hsla(210, 18%, 87%, 1);
  }

  h3 {
    font-size: 1.1em;
    font-weight: 500;
    margin: 10px;
  }

  ul {
    background: #F4F4F4;
    border-radius: 10px;
    padding: 10px 10px 20px 40px;
    margin: 20px 40px;
  }

  li {
    list-style: disc;
  }

  p {
    margin: 20px 40px;

    background: #F4F4F4;
    padding: 10px;
    border-radius: 10px;
  }
`

const ReturnHome = styled('a')`
  background: #ea8d0d;
  color: white;
  padding: 10px 20px;
  margin: 20px auto;
  border-radius: 10px;
`

const About = () => {
    return <React.Fragment></React.Fragment>
}

const Faq = () => {
    return <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <ReturnHome href={"/"}>Return Home</ReturnHome>
        <MdBase>{faqMd}</MdBase>
        <ReturnHome href={"/"}>Return Home</ReturnHome>
    </div>
}

const MdReader = (props) => {
    return <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <ReturnHome href={"/"}>Return Home</ReturnHome>
        <MdBase>{props.data}</MdBase>
        <ReturnHome href={"/"}>Return Home</ReturnHome>
    </div>
}

const AppRouter = () => {
    return <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/about" element={<App />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/imprint" element={<MdReader data={imprintMdEn} />} />
                <Route path="/impressum" element={<MdReader data={imprintMdDe} />} />
                <Route path="/privacy-policy" element={<MdReader data={privacyMdEn} />} />
                <Route path="/datenschutz" element={<MdReader data={privacyMdDe} />} />
                <Route path="/general-terms-of-use" element={<MdReader data={termsOfUseMdEn} />} />
                <Route path="/general-terms-of-use-de" element={<MdReader data={termsOfUseMdDe} />} />
            </Routes>
    </Router>
}

export default AppRouter;
