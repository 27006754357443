import { middleTabCenter, middleTabLeft, middleTabRight } from "../util/data";

/**
 * Element that builds the middle tab when selected
 * @param {any} props
 * @returns {JSX.Element}
 */
export function TabContentLeft(props) {
  /**
   * Template used so that functions can map out tabs
   * @param {event} e
   * @param {number} i
   * @returns {JSX.Element}
   */
  const buildTabs = (e, i) => (
      <div key={i}>
        <img className="w-full" src={e.src} alt={e.alt} />
        <p className="mx-0 mt-2 mb-6 text-xl font-bold">{e.pTop}</p>
        <p className="mx-0 my-2 text-gray-400">{e.pBottom}</p>
      </div>
  );

    const tabLeft = buildTabs(props.tab1, 1);
    const tabMiddle = buildTabs(props.tab2, 2);
    //const tabRight = middleTabRight.map((e, i) => buildTabs(e, i));

  return (
      <div id="tab-2-content" className="text-center hover:white-img">
        <div className="justify-center gap-4 sm:flex-grid sm:items-start tab-2-content-top sm:flex" style={{maxWidth:"800px", margin:"auto", alignItems:"center", flexDirection:"column", marginBottom:"20px"}}>
          <p className="mx-0 mt-2 mb-4 text-xl leading-none sm:text-2xl">
            {props.p}
          </p>
          <a
              className="items-center inline-block px-5 py-3 mr-2 rounded-xl text-sm text-center text-white uppercase border-none cursor-pointer lg:text-base btn hover:opacity-90"
              href={props.href}
              rel={props.rel}
              target={props.target}
          >
            <span>{props.anchorText}</span>
          </a>
        </div>
        <div className="items-baseline w-9/12 grid-cols-3 gap-8 mx-auto mt-8 text-center sm:flex ">
          {tabLeft}
          {tabMiddle}
        </div>
      </div>
  );
}
