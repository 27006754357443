import "./styles/app.css";

import { Footer } from "./components/Footer";
import { Hero } from "./components/Hero";
import { TabContentCurrent } from "./components/TabContentCurrent";
import { TabContentLeft } from "./components/TabContentLeft";
import { TabContentMiddle } from "./components/TabContentMiddle";
import { TabContentRight } from "./components/TabContentRight";
import { TabSelector } from "./components/TabSelector";
import { useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";


/**
 * Main page
 * @returns {JSX.Element}
 */
export default function App() {
  const appUrl = "https://app.playalanguage.com";
  const contentLeft = (
    <TabContentLeft
      alt="Country Feeling Abroad"
      href={appUrl}
      anchorText="Start learning"
      p="Use any device* to travel virtually to the country of your choice, and take part in an interactive adventure to learn the local language."
      rel="noreferrer"
      span="Start learning"
      tab1={{
        src: "https://lq-gc-graphics.s3.amazonaws.com/laptop_AT7.png",
        pTop: "Interactive city maps",
        pBottom:
            "Visit different places and navigate through exciting stories in authentic maps.",
        alt: "laptop",
      }}
      tab2={{
        src: "https://lq-gc-graphics.s3.amazonaws.com/smartphone_tablet_AT7.png",
        pTop: "Experience culture",
        pBottom: "Understand the local culture through the lenses of rich and interesting characters.",
        alt: "smartphones",
      }}
      target="_blank"
    />
  );

  const contentMiddle = (
    <TabContentMiddle
      target="_blank"
      anchorText="Start learning"
      href={appUrl}
      p="Play an interactive adventure with quest-based stories and enjoy learning a new language!"
      rel="noreferrer"
      tab1={{
        src: "https://lq-gc-graphics.s3.amazonaws.com/smartphone_hands_AT7.png",
        pTop: "Practice and replay",
        pBottom:
            "Practice what you learn in virtual conversations with different characters.",
        alt: "tv",
      }}
      tab2={{
        src: "https://lq-gc-graphics.s3.amazonaws.com/tablet_hands_AT7.png",
        pTop: "Decide your own path",
        pBottom: "Pick topics you want to learn, and define on your own how to solve the quests and puzzles of each story.",
        alt: "tv",
      }}
    />
  );

  const contentRight = (
    /*<TabContentRight
      href={appUrl}
      p="Choose one plan"
      rel="noreferrer"
      span="Start for free"
      target="_blank"
      th1="Basic"
      th2="Standard"
      th3="Premium"

    />*/
      <TabContentRight
          target="_blank"
          alt="Motivation and Fun"
          href={appUrl}
          anchorText="Start learning"
          header="Real motivation takes place when it is you who wants to continue!"
          paragraph="All language quests are part of an exciting adventure– and YOU are the protagonist!"
          paragraph2="Discover all stories step by step, learn a language and have a fun time!"

          rel="noreferrer"
          span="Start learning"
          src="https://lq-gc-graphics.s3.amazonaws.com/user_tablet_AT7.png"
          target="_blank"
      />
  );

  const tabStyles = "px-0 py-0 hover:cursor-pointer hover:text-white";
  const [tabContent, setTabContent] = useState(contentLeft);
  const [borderBottom, setborderBottom] = useState(tabStyles);

  /**
   * When clicked reset the border on the tab and then set the border on the tab selected and set the contents of the tab.
   * @param {Event} e
   */
  function onClickSetTab(e) {
    resetBorder(e);
    setborderBottom(borderBottom);

    switch (e.currentTarget.id) {
      case "tab-3":
        setTabContent(contentRight);
        break;
      case "tab-2":
        setTabContent(contentMiddle);
        break;
      case "tab-1":
      default:
        setTabContent(contentLeft);
        break;
    }
  }

  return (
    <div className="h-screen p-0 m-0 bg-black">
      <div className="font-sans antialiased text-gray-400 bg-black">
        <Hero
          alt="Play a language"
          buttonText="Play a language! for free"
          h1="Learn a new language"
          href="https://app.playalanguage.com/signup"
          p="Through interactive and quest-based stories."
          rel="noreferrer"
          signInText="sign in"
          target="_blank"
        />

        <TabSelector
          borderBottom={borderBottom}
          onClickSetTab={onClickSetTab}
          pLeft="Adventure abroad"
          pMiddle="Get involved in quests"
          pRight="Have fun"
        />

        <TabContentCurrent tabContent={tabContent} />

        <Footer footerParagraph="" />
      <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="playalanguage"
          style={{ background: "#2B373B",  fontSize: "0.8em" }}
          buttonStyle={{ background: "#ea8d0d", color: "#fff", fontSize: "1em" }}
          expires={150}
      >
          This website uses cookies to enhance the user experience. Please visit our {" "}
          {<a style={{
              color: "#fff",
              textDecoration: "underline"
          }} href={"/privacy-policy"}>privacy policy</a>}{" "} for more information.
      </CookieConsent>
      </div>
    </div>
  );
}

function resetBorder(e) {
  const tabBorder = "tab-border";
  const elements = document.getElementsByClassName(tabBorder);

  for (const el of elements) {
    el.classList.remove(tabBorder);
  }

  e.currentTarget.classList.add("tab-border");
}
