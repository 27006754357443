export const faqMd = `# FAQ - Play A Language

## What languages and cities does Play A Language! offer?
We are still at the very beginning with our offering. This is why initially only Spanish in Barcelona and English in London is available. Both cities will only contain a few stories.

## What language levels is Play A Language! made for?
Currently we offer three different levels: For beginners (A1/A2), for intermediates (B1/B2) and for advanced learners (C1/C2). You can easily switch between the language levels while using Play A Language!

## Can I learn a language with Play A Language! alone?
We believe that learning a foreign language is too complex to succeed with just one method alone. No one learns a foreign language only with the help of a language teacher or by attending a classroom course. Nor is it possible to learn a foreign language just by using an app. It is always a combination of several things that leads to success. Play A Language! is a new approach to online language learning: through interactive stories, we provide the feeling of learning directly in the country.

## Are all features already available in Play A Language?
Unfortunately not. Many features are not available at this time. We are working to expand and complete the offering as soon as possible.

## How much does Play A Language cost?
Play A Language! is completely free to use during the alpha and beta phases. We will continue to provide a free offering in the future, but reserve the right to offer certain features as paid premium features.

## Is Play A Language! an app? Which devices is Play A Language! compatible with?
Play A Language! is a purely web-based offering. This means that all you need to use Play A Language! is a browser. In theory, Play A Language! works on all devices: Android and iOS devices, Macs and PCs. However, there may be limitations on certain devices. We are working to ensure that all features will be available on all devices in the future
`;
