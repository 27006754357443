import React from "react";
import {
  footerLeft,
  footerLeftCenter,
  footerRight,
  footerRightCenter,
} from "../util/data";

/**
 * Build the footer of the page
 * @param {any} props
 * @returns {JSX.Element}
 */
export function Footer(props) {
  /**
   * returns a list of anchors to be mapped out
   * @param {Event} e
   * @param {number} i
   * @returns {JSX.Element}
   */
  const listAnchor = (e, i) => (
    <li key={i}>
      <a
        className="leading-10 hover:text-gray-400"
        href={e.href}
        rel="noreferrer"
        target="_blank"
      >
        {e.text}
      </a>
    </li>
  );

  const dataLeft = footerLeft.map((e, i) => listAnchor(e, i));
  const dataLeftCenter = footerLeftCenter.map((e, i) => listAnchor(e, i));
  const dataRight = footerRight.map((e, i) => listAnchor(e, i));
  const dataRightCenter = footerRightCenter.map((e, i) => listAnchor(e, i));

  return (
    <footer className="mx-auto overflow-auto text-left bg-black footer ">
      <p className="mt-2 mb-6 text-sm hover:text-white sm:text-base">
        {props.footerParagraph}
      </p>
      <div className="grid grid-cols-2 gap-2 mb-8 text-sm sm:grid-cols-4 sm:mb-16">
        <ul className="list-none">{dataLeft}</ul>
        <ul className="list-none">{dataLeftCenter}</ul>
        <ul className="list-none">{dataRight}</ul>
        <ul className="list-none">{dataRightCenter}</ul>
      </div>
    </footer>
  );
}
