import React, {useState} from "react";
import { FiChevronRight } from "react-icons/fi";
import logo from "../assets/pallogo.png";
import {TypeAnimation} from "react-type-animation";

/**
 *
 * @param {any} props
 * @returns {JSX.Element}
 */
export function Hero(props) {
  const [city,setCity] = useState('Barcelona');
  const headerAnimationWaitTime = 1500;
  return (
    <header className="relative z-0 w-full h-screen showcase after:absolute after:top-0 after:left-0 after:w-full after:h-full">
      <div className="relative z-10 h-24 showcase-top">
        <a href={props.href} target={props.target} rel={props.rel}>
          <img
            alt={props.alt}
            className="absolute w-20 sm:w-44 top-1/2 sm:top-20 left-1/4 mg:left-1/8 lg:left-1/2 lg:top-20 lg:p-8, md:p-8"
            src={logo}
            style={{filter:"hue-rotate(350deg)"}}
          />
        </a>
        <a
          className="absolute right-0 items-center inline-block px-4 py-2 rounded-xl text-base text-white border-none cursor-pointer btn top-2/4 hover:opacity-90"
          href={props.href}
          rel={props.rel}
          target={props.target}
        >
          <span className="capitalize">{props.signInText}</span>
        </a>
      </div>

      <div className="relative z-10 flex flex-col items-center justify-center w-full px-5 py-0 m-auto mt-12 text-center text-gray-400">
        <h1 className="mx-0 mt-0 mb-4 pt-10 text-3xl font-bold text-white sm:text-3xl md:text-4xl lg:text-5xl sm:inline-block">
            <TypeAnimation
              sequence={[
                'Play Español', // Types 'One'
                  () => {
                      setCity("Barcelona")
                  },
                  headerAnimationWaitTime,
                'Play English', // Deletes 'One' and types 'Two'
                  () => {
                      setCity("London")
                  },
                  headerAnimationWaitTime,
                'Play Français', // Types 'Three' without deleting 'Two'
                  () => {
                      setCity("Paris")
                  },
                  headerAnimationWaitTime,
                  'Play Deutsch', // Types 'Three' without deleting 'Two'
                  () => {
                      setCity("Berlin")
                  },
                  headerAnimationWaitTime,
              ]}
              wrapper="div"
              cursor={true}
              repeat={Infinity}
          />
          <span className="text-highlight-primary" >@{city}</span>
        </h1>
        <p className="mx-0 mt-0 mb-8 text-sm font-normal text-center text-white lg:text-xl sm:text-md md:text-lg sm:inline-block">
          {props.p}
        </p>
        <a
          className="items-center opacity-100 px-3 py-2 mr-2 rounded-xl text-white uppercase border-none cursor-pointer lg:py-3 sm:inline-block btn hover:scale-105"
          href={props.href}
          rel={props.rel}
          target={props.target}
          style={{transition: "0.2s transform ease-in-out"}}
        >
          <div>
            <span className="p-6 text-base md:p-8 xl:p-12 md:text-2xl xl:text-2xl">
              {props.buttonText}
            </span>
          </div>
        </a>
          <small style={{fontWeight:"bold"}}>Earlier alpha</small>
      </div>
    </header>
  );
}
